import React, {
  Fragment,
  useCallback,
  useState,
  useMemo,
  useEffect,
} from 'react'
import { Box, Grid, Icon, Typography } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import RailTripScheme from './RailTripScheme'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {
  Check as CheckIcon,
  Brightness2 as NightTrainIcon,
  Eco,
} from '@material-ui/icons'
import { POLICY_RESULT } from '../../../../constants/policy'
import { openPolicyExceededModal } from '../../../../store/modals/modalAction'
import Policy from '../../../../components/reusable/tags/policy'
import GetCompanyIcon from '../../../../components/reusable/getCompanyIcon'
import usePriceFormat from '../../../../hooks/usePriceFormat'

const useStyles = makeStyles((theme) => ({
  tripItemOuter: {
    border: 'solid 1px #ededed',
    borderBottom: 'none',

    '&:last-child': {
      borderBottom: 'solid 1px #ededed',
    },

    '&.opened': {
      borderTop: '8px solid #d8d8d8',
      borderBottom: '7px solid #d8d8d8',
    },

    [theme.breakpoints.down('sm')]: {
      borderRight: 'none',
      borderLeft: 'none',
    },
  },

  topRow: {
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative',

    '&.same-item:before': {
      content: "''",
      display: 'block',
      position: 'absolute',
      top: '-1px',
      right: '-1px',
      bottom: '-1px',
      left: '-1px',
      border: 'solid 2px #0dc5b8',
    },
  },

  topRowLeftBlock: {
    display: 'flex',
    width: '100%',
    padding: '24px 20px',
    borderRight: 'solid 1px #ededed',

    [theme.breakpoints.down('md')]: {
      padding: '18px',
    },

    [theme.breakpoints.down('sm')]: {
      padding: '24px 10px',
      '&.opened-mobile': {
        borderRight: 'none',
      },
    },
  },

  topRowLeftBlockInner: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',

    '&.closed-mobile': {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
  },

  expandedTitle: {
    display: 'flex',
    flex: '1',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: 'normal',
  },

  timeBlock: {
    lineHeight: 'normal',
    marginRight: '52px',
    minWidth: '121px',

    '& $currentTextMark': {
      marginTop: '2px',
      textAlign: 'center',
    },

    [theme.breakpoints.down('md')]: {
      marginRight: '15px',
    },

    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
      marginRight: '20px',
    },

    '&.closed-mobile': {
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
        width: '100%',
        marginBottom: '13px',
      },

      '& $currentTextMark': {
        [theme.breakpoints.down('sm')]: {
          textAlign: 'left',
        },
      },
    },
  },

  providerName: {
    fontSize: '16px',
    fontWeight: 'normal',
    lineHeight: 'normal',
    display: 'flex',
    alignItems: 'center',
  },

  timeBlockText: {
    fontSize: '18px',
    fontWeight: 'bold',

    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },

  currentTextMark: {
    fontSize: '11px',
    fontWeight: 'bold',
    lineHeight: 'normal',
    color: '#0dc5b8',
  },

  generalBlock: {
    fontSize: '16px',
    fontWeight: 'normal',
    lineHeight: 'normal',
    minWidth: '70px',
    marginRight: '22px',

    '&:last-child': {
      marginRight: 0,
      marginBottom: 0,
    },

    [theme.breakpoints.down('md')]: {
      marginRight: '15px',
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },

    '&.closed-mobile': {
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
        width: '100%',
        marginBottom: '13px',
      },
    },
  },

  topTicketButtonsRow: {
    display: 'flex',
    flex: '1',
    alignItems: 'stretch',
    alignSelf: 'stretch',
    width: '100%',
  },

  topRowRightBlock: {
    display: 'flex',
    alignSelf: 'stretch',
    alignItems: 'stretch',
  },

  priceUnavailable: {
    fontSize: '17px',
    fontWeight: 'bold',
    lineHeight: 'normal',
    color: '#d8d8d8',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },

  priceText: {
    fontSize: '24px',
    fontWeight: 'bold',
    lineHeight: 'normal',

    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },

    '&.muted-btn-text': {
      marginTop: '4px',
      fontSize: '12px',
      fontWeight: theme.typography.fontWeightRegular,
      textAlign: 'center',
    },
  },

  solutionBtn: {
    borderRight: 'solid 1px #ededed',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '&:last-child': {
      borderRight: 'none',
    },
  },

  expandedRow: {
    borderTop: 'solid 1px #ededed',
  },

  expandedRowLeft: {
    alignSelf: 'stretch',
    borderRight: 'solid 1px #ededed',
    padding: '25px',
  },

  expandedBtnsRow: {
    borderBottom: 'solid 1px #ededed',
    display: 'flex',
    width: '100%',
    '&:last-child': {
      borderRight: 'none',
    },
  },

  classTitleBlock: {
    borderRight: 'solid 1px #ededed',
    height: '54px',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: 'normal',

    '&:last-child': {
      borderRight: 'none',
    },
  },

  policyLabelBlock: {
    marginTop: '5px',
  },

  ticketTypeBtn: {
    cursor: 'pointer',
    height: '100px',
    display: 'flex',
    flex: '1',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRight: 'solid 1px #ededed',
    paddingLeft: '20px',
    paddingRight: '20px',

    '& $currentTextMark': {
      marginBottom: '6px',
    },

    '&.same-btn': {
      position: 'relative',
      backgroundColor: 'rgba(13,197,184,0.2)',

      '&:before': {
        content: "''",
        display: 'block',
        position: 'absolute',
        top: '-1px',
        right: '-1px',
        bottom: '-1px',
        left: '-1px',
        border: 'solid 2px #0dc5b8',
      },
    },

    '&.inactive-btn': {
      cursor: 'default',
    },

    '&:last-child': {
      borderRight: 'none',
    },
  },

  optHeaderInner: {
    height: '55px',
    padding: '0 10px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  optHeaderSide: {
    maxWidth: '57px',
    textAlign: 'right',
  },

  optHeaderBtn: {
    cursor: 'pointer',
    textDecoration: 'underline',
    fontSize: '14px',
  },

  optHeaderText: {
    textAlign: 'center',
    flex: '1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
  },

  checkmarkIcon: {
    color: '#34c300',
    fontSize: '20px',
    marginRight: '5px',
    marginBottom: '4px',
  },

  nightTrainIcon: {
    transform: 'rotate(150deg)',
    fontSize: '18px',
  },

  co2Label: {
    fontSize: theme.spacing(1.5),
    padding: theme.spacing(0.5, 1.5),
    borderRadius: theme.spacing(3),
    color: theme.palette.policyTextGreen,
    backgroundColor: theme.palette.policyGreen,
    fontWeight: 500,
    display: 'inline-flex',
    alignItems: 'center',
  },

  fastTrainLabel: {
    fontSize: theme.spacing(1.5),
    placeSelf: 'flex-end',
  },

  nightTrainNotice: {
    // height: '50px',
    display: 'flex',
    alignItems: 'center',
    padding: '12px',
    borderRadius: '8px',
    justifyContent: 'flex-start',
    backgroundColor: '#FFF6CC',
    fontWeight: '500',
    gap: '12px',
  },
}))

const ticketTypeKeys = ['nonRebookable', 'rebookable', 'refundable']

const RailTripSearchItem = ({ item, currTrip, onSelectTicket, lgSize = 8 }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [state, setState] = useState({
    isMounted: true,
    isOpen: false,
    seatType: null,
    berthType: null,
  })

  const { formattedCurrency, formatPrice } = usePriceFormat()
  const { co2 } = item.schedule

  const bertTypes = useMemo(() => ['SlCouchette', 'SlShared', 'SlSingle'], [])

  const classesByBerth = useMemo(
    () => ({
      SlShared: ['002'],
      SlCouchette: ['002'],
      SlSingle: ['001'],
    }),
    []
  )

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const isSame = useMemo(() => {
    return !!item?.isSame
  }, [item, currTrip])

  useEffect(() => {
    if (!state.seatType) {
      if (!!item?.solutions?.seats && !item?.solutions?.berth) {
        setState({ ...state, seatType: 'seats' })
      } else if (!item?.solutions?.seats && !!item?.solutions?.berth) {
        setState({ ...state, seatType: 'berth' })
      }
    }
  }, [state, item])

  const solutions = useMemo(() => {
    if (!state.seatType) return {}
    if (state.seatType === 'berth' && !state.berthType) return {}
    if (state.seatType === 'seats') return item?.solutions?.seats || {}
    if (state.seatType === 'berth') {
      return item?.solutions?.berth?.[state.berthType] || {}
    }
  }, [state.seatType, state.berthType, item])

  const minPolicy = useMemo(() => {
    const seats = item?.solutions?.seats || {}
    const berth = item?.solutions?.berth || {}
    const classKeys = ['002', '001']
    const berthTypes = ['SlCouchette', 'SlShared', 'SlSingle']
    const minP = {
      '001': null,
      '002': null,
    }

    // console.log(item)

    classKeys.forEach((cl) => {
      const listOfClasses = []
      if (seats?.[cl]) {
        listOfClasses.push(seats[cl])
      }
      if (seats?.[`${cl}_cs`]) {
        listOfClasses.push(seats?.[`${cl}_cs`])
      }

      for (let clClass of listOfClasses) {
        for (let clKey in clClass) {
          const sPolicy = parseInt(clClass[clKey].exceedsPolicy || 0)
          // console.log(`${cl} seat ${clKey} = ${sPolicy}`)
          if (minP[cl] === undefined || minP[cl] === null || minP[cl] > sPolicy)
            minP[cl] = sPolicy
        }
      }

      berthTypes.forEach((bt) => {
        const btSolutions = berth?.[bt]?.[cl]
        if (!!btSolutions && typeof btSolutions === 'object') {
          for (let key in btSolutions) {
            const bPolicy = parseInt(btSolutions[key]?.exceedsPolicy || 0)
            if (
              minP[cl] === undefined ||
              minP[cl] === null ||
              minP[cl] > bPolicy
            )
              minP[cl] = bPolicy
          }
        }
      })
    })

    for (let classKey in minP) {
      minP[classKey] =
        !!minP[classKey] && minP[classKey] >= 0 ? minP[classKey] : 0
    }
    // console.log(minP)

    return minP
  }, [item])

  const isActiveSolution = useCallback(
    (classKey, ticketType) => {
      const solution = solutions?.[classKey]?.[ticketType]
      return isSame && !!solution?.isSame
    },
    [solutions]
  )

  const getMinSeatPrice = useCallback(
    (key = null) => {
      let minPrice = null
      const iterableSeats = item?.solutions?.seats || {}

      for (let classKey in iterableSeats) {
        if (!!key && classKey !== key && classKey !== `${key}_cs`) continue
        const classSeats = iterableSeats[classKey] || {}

        for (let seatKey in classSeats) {
          const solution = classSeats[seatKey]
          if (!solution?.price?.Amount) continue
          const price = parseInt(solution.price.Amount)
          if (minPrice === null || minPrice > price) minPrice = price
        }
      }
      return minPrice
    },
    [item]
  )

  const getMinBerthPrice = useCallback(
    (keys = null, descr = null) => {
      let minPrice = null
      const iterableBerth = item?.solutions?.berth
      const keysArray = !!keys && typeof keys === 'string' ? [keys] : keys

      for (let descrKey in iterableBerth) {
        if (!!descr && descr !== descrKey) continue
        const classBerth = iterableBerth?.[descrKey] || {}
        for (let classKey in classBerth) {
          if (!!keysArray?.length && !keysArray.includes(classKey)) continue
          const flexBerth = classBerth[classKey] || {}
          for (let flexKey in flexBerth) {
            const solution = flexBerth[flexKey]
            if (!solution?.price?.Amount) continue
            const price = parseInt(solution.price.Amount)

            if (
              solution.exceedsPolicy === POLICY_RESULT.BLOCK ||
              solution.exceedsPolicy === POLICY_RESULT.HIDE
            )
              continue

            if (minPrice === null || minPrice > price) minPrice = price
          }
        }
      }
      return minPrice
    },
    [item]
  )

  const getClassMinPrice = useCallback(
    (key = null) => {
      let minPrice = getMinSeatPrice(key)
      const berthMinPrice = getMinBerthPrice(key)
      if (!minPrice && !berthMinPrice) return null
      return !minPrice || (!!berthMinPrice && berthMinPrice < minPrice)
        ? berthMinPrice
        : minPrice
    },
    [getMinSeatPrice, getMinBerthPrice]
  )

  const getSolution = useCallback(
    (classKey, ticketType) => {
      const solution = solutions?.[classKey]?.[ticketType]
      return solution || null
    },
    [solutions]
  )

  const getSolutionPrice = useCallback(
    (classKey, ticketType) => {
      const solution = getSolution(classKey, ticketType)
      const price = solution?.price?.Amount
      return !!price ? parseInt(price) : null
    },
    [getSolution]
  )

  const getSolutionPolicy = useCallback(
    (classKey, ticketType) => {
      const solution = getSolution(classKey, ticketType)
      return solution?.exceedsPolicy || 0
    },
    [getSolution]
  )

  const handleSelectTicket = useCallback(
    (classKey, ticketType) => {
      const solution = getSolution(classKey, ticketType)
      if (!solution?.price?.Amount || !item?.schedule) return false

      const res = { ...item.schedule, solution: { ...solution } }

      const policy = getSolutionPolicy(classKey, ticketType)
      if (policy === POLICY_RESULT.BLOCK) {
        const allowBypassingPolicy = true
        return dispatch(
          openPolicyExceededModal({
            allowReasons: allowBypassingPolicy,
            clickHandler: () => onSelectTicket(res),
          })
        )
      }

      onSelectTicket(res)
    },
    [onSelectTicket, getSolution, item, getSolutionPolicy]
  )

  const showNightTrainIndicator = useMemo(() => {
    return item?.schedule?.segments?.find((s) => s.railvehicle.Code === 'NT')
  }, [item])

  const isNightTrain = useMemo(() => {
    return !!item?.solutions?.berth
  }, [item])

  const changeSelection = useCallback(
    (type) => {
      if (type === 'seat') {
        setState({ ...state, seatType: null, berthType: null })
      } else if (type === 'berth') {
        setState({ ...state, berthType: null })
      }
    },
    [state]
  )

  const hasSeatTypeChoice = useMemo(() => {
    return !!item?.solutions?.seats && !!item?.solutions?.berth
  }, [item])

  const isActiveStep = useCallback(
    (type = 'seats', berthType = null) => {
      if (type === 'seats' && !!item?.solutions?.seats) {
        for (let classKey in item?.solutions.seats) {
          const classSeats = item.solutions.seats[classKey] || {}
          for (let seatKey in classSeats) {
            if (classSeats[seatKey]?.isSame) return true
          }
        }
      } else if (type === 'berth' && !!item?.solutions?.berth) {
        for (let descrKey in item.solutions.berth) {
          if (!!berthType && berthType !== descrKey) continue
          const classBerth = item.solutions.berth?.[descrKey] || {}
          for (let classKey in classBerth) {
            const flexBerth = classBerth[classKey] || {}
            for (let flexKey in flexBerth) {
              if (flexBerth[flexKey]?.isSame) return true
            }
          }
        }
      }

      return false
    },
    [item]
  )

  const seatTypeOpts = useMemo(() => {
    if (!state.seatType) {
      return [
        {
          text: 'night seats header',
          type: 'seat',
          value: 'seats',
          minPrice: getMinSeatPrice(),
          isSame: isActiveStep('seats'),
        },
        {
          text: t('night berth header'),
          type: 'seat',
          value: 'berth',
          minPrice: getMinBerthPrice(),
          isSame: isActiveStep('berth'),
        },
      ]
    }

    if (!state.berthType && state.seatType === 'berth') {
      return bertTypes.map((bt) => ({
        text: `berth ${bt} header`,
        type: 'berth',
        value: bt,
        minPrice: getMinBerthPrice(classesByBerth[bt], bt),
        isSame: isActiveStep('berth', bt),
      }))
    }
    return null
  }, [
    state.seatType,
    state.berthType,
    bertTypes,
    getMinSeatPrice,
    getMinBerthPrice,
    isActiveStep,
    classesByBerth,
  ])

  const tktClasses = useMemo(() => {
    if (state.seatType === 'berth') {
      return classesByBerth?.[state.berthType || ''] || ['002']
    } else if (state.seatType === 'seats') {
      if (isNightTrain) return ['002']
      const classes = ['002']
      if (!!item?.solutions?.seats?.['002_cs']) classes.push('002_cs')
      classes.push('001')
      if (!!item?.solutions?.seats?.['001_cs']) classes.push('001_cs')
      return classes
    }
    return ['002', '001']
  }, [state.seatType, state.berthType, item, classesByBerth, isNightTrain])

  const getClassTitle = useCallback(
    (key) => {
      if (isNightTrain && tktClasses?.length === 1) return t('flexibility')
      const clsTxt = t('class')
      const clsCalmTxt = t('class calm')
      const ticketTitles = {
        '001': `1 ${clsTxt}`,
        '002': `2 ${clsTxt}`,
        '001_cs': `1 ${clsCalmTxt}`,
        '002_cs': `2 ${clsCalmTxt}`,
      }

      return ticketTitles[key] || this.t('unknown')
    },
    [t, isNightTrain, tktClasses]
  )

  const timeText = useMemo(() => {
    const startDate = item?.schedule?.scheduleSolution?.railstart?.dateTime
    const endDate = item?.schedule?.scheduleSolution?.railend?.dateTime
    const sMoment = moment(startDate)
    const eMoment = moment(endDate)
    if (!sMoment.isValid() || !eMoment.isValid()) return ''
    return `${sMoment.format('HH:mm')} - ${eMoment.format('HH:mm')}`
  }, [item])

  const durationText = useMemo(() => {
    const startDate = item?.schedule?.scheduleSolution?.railstart?.dateTime
    const endDate = item?.schedule?.scheduleSolution?.railend?.dateTime
    const sMoment = moment(startDate)
    const eMoment = moment(endDate)
    if (!sMoment.isValid() || !eMoment.isValid()) return ''
    const diffHrs = eMoment.diff(sMoment, 'hours')
    const diffMin = eMoment.diff(sMoment, 'minutes') - diffHrs * 60

    return `${diffHrs > 0 ? diffHrs + 'h' : ''} ${
      diffMin > 0 ? diffMin + 'm' : ''
    }`
  }, [item])

  const changes = useMemo(() => {
    return item?.schedule?.segments?.length > 0
      ? item.schedule.segments.length - 1
      : 0
  }, [item])

  const setSeatPref = useCallback(
    (opt) => {
      if (opt.type === 'seat') setState({ ...state, seatType: opt.value })
      if (opt.type === 'berth') setState({ ...state, berthType: opt.value })
    },
    [state]
  )

  const toggle = () => {
    setState({ ...state, isOpen: !state.isOpen })
  }
  const isMobileOpen = useMemo(
    () => !!isMobile && !!state.isOpen,
    [isMobile, state.isOpen]
  )
  const isClosedMobile = useMemo(
    () => isMobile && !state.isOpen,
    [isMobile, state.isOpen]
  )

  const durationBlock = useMemo(() => {
    return (
      <Box
        key="duration-block"
        className={`${classes.generalBlock} ${
          isClosedMobile ? 'closed-mobile' : ''
        }`}
      >
        {!!isMobile ? `${t('duration')} ` : ''}
        {durationText}
      </Box>
    )
  }, [isMobile, isClosedMobile, durationText])

  const seatTypeHeaders = useMemo(() => {
    const isSeatHeader = hasSeatTypeChoice && state.seatType
    const isBerth = state.seatType === 'berth'

    if (!isSeatHeader && !isBerth) return null

    return (
      <Box
        key="seat-type-headers"
        className="col-6 col-xl-4 expanded-row-right"
      >
        {!!isSeatHeader && (
          <Box key="seat-type-header" className={classes.expandedBtnsRow}>
            <Box key="opt-header-inner" className={classes.optHeaderInner}>
              <Box key="opt-header-side" className={classes.optHeaderSide} />
              <Box key="opt-header-text" className={classes.optHeaderText}>
                <CheckIcon className={classes.checkmarkIcon} />
                <span
                  key="opt-header-text-inner"
                  className={classes.optHeaderTextInner}
                >
                  {t(`night ${state.seatType} header`)}
                </span>
              </Box>
              <Box key="opt-header-btn-block" className={classes.optHeaderSide}>
                <span
                  key="opt-header-btn"
                  onClick={() => changeSelection('seat')}
                  className={classes.optHeaderBtn}
                >
                  {t('edit btn')}
                </span>
              </Box>
            </Box>
          </Box>
        )}

        {!!isBerth && (
          <Box key="berth-type-header" className={classes.expandedBtnsRow}>
            <Box key="opt-header-inner" className={classes.optHeaderInner}>
              <Box key="opt-header-side" className={classes.optHeaderSide} />
              <Box key="opt-header-text" className={classes.optHeaderText}>
                {!!state.berthType && (
                  <CheckIcon className={classes.checkmarkIcon} />
                )}
                <span key="opt-header-text-inner">
                  {t(
                    !state.berthType
                      ? 'compartment'
                      : `berth ${state.berthType} header`
                  )}
                </span>
              </Box>
              <Box key="opt-header-btn-block" className={classes.optHeaderSide}>
                {!!state.berthType && (
                  <span
                    key="opt-header-btn"
                    onClick={() => changeSelection('berth')}
                    className={classes.optHeaderBtn}
                  >
                    {t('edit btn')}
                  </span>
                )}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    )
  }, [hasSeatTypeChoice, state.berthType, state.seatType, changeSelection])
  // console.log(getClassMinPrice('002'))
  // console.log(minPolicy['002'])

  return (
    <Box
      key="tkt-trip-item-outer"
      className={`${classes.tripItemOuter} ${state.isOpen ? 'opened' : ''}`}
    >
      <Grid
        key="top-row"
        onClick={toggle}
        container
        spacing={0}
        className={`${classes.topRow} ${
          isSame && !state.isOpen ? 'same-item' : ''
        }`}
      >
        <Grid item xs={!!isMobile && !!state.isOpen ? 12 : 6} lg={lgSize}>
          <Box
            key="top-row-left-row"
            className={`${classes.topRowLeftBlock} ${
              isMobileOpen ? 'opened-mobile' : ''
            }`}
          >
            <Box
              key="inner-left-block"
              className={`${classes.topRowLeftBlockInner} ${
                isClosedMobile ? 'closed-mobile' : ''
              }`}
            >
              {isClosedMobile && durationBlock}
              <Box
                key="time-block"
                className={`${classes.timeBlock} ${
                  isClosedMobile ? 'closed-mobile' : ''
                }`}
              >
                <Box
                  key="time-block-inner-container"
                  style={{ display: 'inline-block' }}
                >
                  <Box
                    key="time-block-text"
                    className={classes.timeBlockText}
                    style={{ display: 'inline-block' }}
                  >
                    {timeText}
                  </Box>
                  {showNightTrainIndicator && (
                    <Box
                      style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                      }}
                      ml={1}
                    >
                      <NightTrainIcon className={classes.nightTrainIcon} />
                    </Box>
                  )}
                  {!!isSame && (
                    <Box
                      key="current-text-mark"
                      className={classes.currentTextMark}
                    >
                      {t('current proposal')}
                    </Box>
                  )}
                </Box>
              </Box>
              {!isClosedMobile && durationBlock}
              <Box key="changes-block" className={classes.generalBlock}>
                {changes > 0 ? `${changes} ` : ''}
                {t(changes > 0 ? 'change' : 'filter directly', {
                  count: changes,
                })}{' '}
                {!!isMobile && item?.schedule?.providerName}
                {!!isMobile &&
                  item?.schedule.hasFastTrain &&
                  ' ' + t('express train')}
              </Box>
            </Box>
            {!isMobile && item?.schedule.hasFastTrain && (
              <Box mr={1} className={classes.fastTrainLabel}>
                {t('express train')}
              </Box>
            )}
            {!isMobile && (
              <Box
                key="inner-right-block-provider"
                className={classes.providerName}
              >
                <GetCompanyIcon
                  height={20}
                  width={20}
                  company={item?.schedule?.scheduleSolution?.serverProviderCode}
                  backupName={item?.schedule?.providerName}
                />
              </Box>
            )}
          </Box>
        </Grid>
        {!isMobileOpen && (
          <Grid
            item
            xs={6}
            lg={12 - lgSize}
            className={classes.topRowRightBlock}
          >
            <Box
              key="top-ticket-buttons-row"
              className={classes.topTicketButtonsRow}
            >
              {!state.isOpen ? (
                <Fragment>
                  <Box key="ticket-002" className={classes.solutionBtn}>
                    {!!getClassMinPrice('002') &&
                    minPolicy['002'] !== POLICY_RESULT.HIDE ? (
                      <Fragment>
                        <Box key="price-text" className={classes.priceText}>
                          {formatPrice(getClassMinPrice('002'), {
                            appendCurrency: true,
                          })}
                        </Box>
                        {!!minPolicy['002'] && (
                          <Box className={classes.policyLabelBlock}>
                            <Policy
                              policy={minPolicy['002']}
                              small
                              labelStyles={{ padding: 0 }}
                            />
                          </Box>
                        )}
                      </Fragment>
                    ) : (
                      <Box
                        key="price-unavailable"
                        className={classes.priceUnavailable}
                      >
                        {t('not available')}
                      </Box>
                    )}
                  </Box>
                  <Box key="ticket-001" className={classes.solutionBtn}>
                    {!!getClassMinPrice('001') &&
                    minPolicy['001'] !== POLICY_RESULT.HIDE ? (
                      <Fragment>
                        <Box key="price-text" className={classes.priceText}>
                          {formatPrice(getClassMinPrice('001'), {
                            appendCurrency: true,
                          })}
                        </Box>
                        {!!minPolicy['001'] && (
                          <Box className={classes.policyLabelBlock}>
                            <Policy
                              policy={minPolicy['001']}
                              small
                              labelStyles={{ padding: 0 }}
                            />
                          </Box>
                        )}
                      </Fragment>
                    ) : (
                      <Box
                        key="price-unavailable"
                        className={classes.priceUnavailable}
                      >
                        {t('not available')}
                      </Box>
                    )}
                  </Box>
                </Fragment>
              ) : (
                <Box key="expanded-title" className={classes.expandedTitle}>
                  {' '}
                  {t('ticket options')}{' '}
                </Box>
              )}
            </Box>
          </Grid>
        )}
      </Grid>
      {!!state.isOpen && (
        <Grid
          key="expanded-row"
          container
          spacing={0}
          direction={isMobile ? 'column-reverse' : 'row'}
          className={`${classes.expandedRow}`}
        >
          <Grid
            item
            xs={12}
            md={6}
            lg={lgSize}
            className={classes.expandedRowLeft}
          >
            {showNightTrainIndicator && !isNightTrain && (
              <Box mb={2} className={classes.nightTrainNotice}>
                <NightTrainIcon className={classes.nightTrainIcon} />

                {t('night berth disabled notice')}
              </Box>
            )}

            {co2 ? (
              <Box mb={2} className={classes.co2Label}>
                <Eco fontSize="small"></Eco>
                <span style={{ paddingTop: '2px' }}>
                  {co2} {t('kilo')} {t('carbon dioxide')}
                </span>
              </Box>
            ) : null}

            <RailTripScheme segments={item?.schedule?.segments || []} />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={12 - lgSize}
            className={classes.expandedRowRight}
          >
            {seatTypeHeaders}
            {seatTypeOpts?.length > 0 ? (
              seatTypeOpts.map((sOpt) => (
                <Box
                  key={`seatopts-row-${sOpt.type}-${sOpt.value}`}
                  className={classes.expandedBtnsRow}
                >
                  <Box
                    key={`seatopts-btn-${sOpt.type}-${sOpt.value}`}
                    className={`${classes.ticketTypeBtn} ${
                      sOpt.isSame ? 'same-btn' : ''
                    }`}
                    onClick={sOpt.minPrice ? () => setSeatPref(sOpt) : null}
                  >
                    {!!sOpt.minPrice ? (
                      <Fragment>
                        <Box key="price-text" className={classes.priceText}>
                          {formatPrice(sOpt.minPrice, { appendCurrency: true })}
                        </Box>
                        <Box
                          key="price-text-muted"
                          className={`${classes.priceText} muted-btn-text`}
                        >
                          {t(sOpt.text)}
                        </Box>
                      </Fragment>
                    ) : (
                      <Box
                        key="price-unavailable"
                        className={classes.priceUnavailable}
                      >
                        {t('not available')}
                      </Box>
                    )}
                  </Box>
                </Box>
              ))
            ) : (
              <Fragment>
                <Box
                  key="expanded-row-right"
                  className="col-6 col-xl-4 expanded-row-right"
                >
                  <Box
                    key="class-titles-row"
                    className={classes.expandedBtnsRow}
                  >
                    {tktClasses.map((clsKey, i) => (
                      <Box
                        key={`classTitleBlock-${i}`}
                        className={classes.classTitleBlock}
                      >
                        {getClassTitle(clsKey)}
                      </Box>
                    ))}
                  </Box>
                </Box>
                {ticketTypeKeys.map((TT) => {
                  return (
                    <Box
                      key={`expanded-btns-row-${TT}`}
                      className={classes.expandedBtnsRow}
                    >
                      {tktClasses.map((CK) => {
                        const isActive = isActiveSolution(CK, TT)
                        const sPrice = getSolutionPrice(CK, TT)
                        const policy = getSolutionPolicy(CK, TT)

                        return (
                          <Box
                            key={`expanded-tkt-btn-${CK}`}
                            className={`${classes.ticketTypeBtn} ${
                              isActive ? 'same-btn' : ''
                            } ${
                              !sPrice || policy === POLICY_RESULT.HIDE
                                ? 'inactive-btn'
                                : ''
                            }`}
                            onClick={() =>
                              sPrice && policy !== POLICY_RESULT.HIDE
                                ? handleSelectTicket(CK, TT)
                                : false
                            }
                          >
                            {!!sPrice && policy !== POLICY_RESULT.HIDE ? (
                              <Fragment>
                                {isActive && (
                                  <Box
                                    key="current-text-mark"
                                    className={classes.currentTextMark}
                                  >
                                    {t('current proposal')}
                                  </Box>
                                )}
                                <Box
                                  key="price-text"
                                  className={classes.priceText}
                                >
                                  {formatPrice(sPrice)}
                                </Box>
                                <Box
                                  key="price-text-muted"
                                  className={`${classes.priceText} muted-btn-text`}
                                >
                                  {t(TT)}
                                </Box>
                                {!!policy && (
                                  <Typography
                                    align="center"
                                    className={classes.policyLabelBlock}
                                    variant="caption"
                                  >
                                    <Policy policy={policy} />
                                  </Typography>
                                )}
                              </Fragment>
                            ) : (
                              <Box
                                key="price-unavailable"
                                className={classes.priceUnavailable}
                              >
                                {t('not available')}
                              </Box>
                            )}
                          </Box>
                        )
                      })}
                    </Box>
                  )
                })}
              </Fragment>
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  )
}

export default RailTripSearchItem
